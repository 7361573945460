import React, {useEffect} from 'react';
import { bindActionCreators } from 'redux';
import * as Sentry from "@sentry/react";
import { connect } from 'react-redux'
import {getTokenRequest} from '../db/action/token';
import {isEmpty} from 'lodash'
import Footer from '../layout/footer';
import Content from '../layout/content';
import MainMenu from '../components/MainMenu';
import Header from '../components/Header';
import {Spinner, Row, Col, Container} from 'react-bootstrap';
import { BrowserTracing } from "@sentry/tracing";
Sentry.init({
  dsn: "https://99ac981089ff4fe498784cea44550c3b@o1348664.ingest.sentry.io/6628109",
  integrations: [new BrowserTracing()],
  environment: 'production - sentry'
});

function App(props) {
  useEffect(() => {
  }, []);
  
  return props.token.accessToken ? ( 
    <div className={`main-wrapper`}>
    <Content/>
    </div>
  ) :   <div className={`main-wrapper`}>
  <Content/>
  </div>;
}

const mapStateToProps = (state) => {
  return {
    token: state.token || {}
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    getTokenRequest,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(App);