import React, {useState, useEffect} from 'react';
import moment from 'moment';
import {Col, Row, InputGroup, Form, Button, Card, Table, Image, ListGroup} from 'react-bootstrap';
import { useNavigate} from 'react-router-dom';
import logoImg from '../../../asset/images/logo.png'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PageHeader from '../../PageHeader';
import Logo from '../../Logo';
import Error from '../../Error';
import aboutSvg from '../../../asset/images/aboutimg.svg';

const LAbout = (props) => {
    return (
        <React.Fragment>
  <section className="about" id='about'>
    <div className="container">
      <div className="row">
        <div className="col-12 col-sm-12 col-lg-6">
          <img  src={aboutSvg} alt="" />
        </div>
        <div className="col-12 col-sm-12 col-lg-6">
          <h5>OUR COMPANY</h5>
          <h2>Intense Emotions<br/>Words About Us</h2>
          <p>Mugam is a textile brand that never sells anything according to the business. Instead, it is attached to people's emotions. It brainstorms the trending design and influences and spreads positivity in the people. Finally, It speaks loud about people's opinions. It gives quality material 
to everyone irrespective of any classification.</p>
        </div>
      </div>
    </div>
  </section>    
        </React.Fragment>
    )
}

export default connect()(LAbout);