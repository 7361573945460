import {  
  INSERT_CONTACT_REQUEST,
  INSERT_CONTACT_SUCCESS
} from '../../actionTypes/contact';

const contactReducer = (state = {}, action) => {
  switch (action.type) {  
    case INSERT_CONTACT_REQUEST:
      state = { ...state, insert: {} };
      break;  
  case INSERT_CONTACT_SUCCESS:
      state = { ...state,
                insert:{
                    ...action.payload  
                }
            };
      break;  
  default:
      break;
  }
  return state;
};
export default contactReducer;