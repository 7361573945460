import React from 'react';
import {Col, Row, InputGroup, Form, Button, Card, Table, Image, ListGroup} from 'react-bootstrap';
import { useNavigate} from 'react-router-dom';
import logoImg from '../../asset/images/logo.png';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
const Logo = (props) => {
    return (
        <Row>
            <Col sm={3}>
                <Card className={'border border-0 mb-4'}>
                    <Image src={logoImg} className={'img-fluid'} alt={'mugam logo'} />
                </Card>
            </Col>
        </Row>    
    )
}

export default connect()(Logo);