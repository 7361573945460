import { Nav, NavDropdown, Navbar, Form, FormControl, Button, Container } from 'react-bootstrap';
import logoPng from '../../asset/images/logo.png';
import heroSvg from '../../asset/images/hero.svg';

const Header = () => {
    return (<>
    <header id="home">
    <div className="container">
      <nav className="navbar navbar-expand-lg navbar-light ">
        
        <a className="navbar-brand" href="#"><img src={logoPng} alt="" width={'100pt'} /></a>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <div className="interactive-menu-button">
            <a href="#" className={'inact'}>
              <span>Menu</span>
            </a>
          </div>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item active">
              <a className="nav-link" data-scroll href="#home">Home<span className="sr-only">(current)</span></a>
            </li>
            <li className="nav-item">
              
              <a className="nav-link" data-scroll href="#about">About us</a>
            </li>
            <li className="nav-item">
              
              <a className="nav-link" data-scroll href="#features">Features</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" data-scroll href="#today">Today</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" data-scroll href="#quote">Quote</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" data-scroll href="#investor">Investor</a>
            </li>
          </ul>
          <form className="contact-btn form-inline my-2 my-lg-0">
            <button onClick={(e) => { e.preventDefault();window.location.href='#footer'; }}>Contact Us</button>
          </form>
        </div>
      </nav>
    </div>
        
    <div className="container-fluid hero" id='home'>
      <img src={heroSvg} alt="" />
      <div className="container">
        
        <h1>Mugam<br/> Textile Brand</h1>
        
        <p>       Mugam, a Textile Brand, has intense emotions with people and technology.<br/> 
        It is a context and concept-driven textile design company.</p>
        <div className="hero-btns">
          
          <a data-scroll href="#about">More About us</a>
          
          <a data-scroll href="#footer">Get in Touch.</a>
        </div>
      </div>
    </div>
  </header>
    </>)
}

export default Header;