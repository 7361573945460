import axios from 'axios';

const https = axios.create({
    baseURL: 'https://api.lab63.org',
    timeout: 5000 
});

https.interceptors.request.use(function (config) {
    const { store } = require('../store');
    const db = store.getState();
    const token = db.token && db.token.accessToken;
    config.headers.Authorization =  'Bearer ' + token;
    return config;
});

export const currencyConversionHttps = axios.create({
    baseURL: 'https://api.apilayer.com/currency_data/convert',
    timeout: 10000
});

export default https;