import React, {useState, useEffect} from 'react';
import moment from 'moment';
import {Col, Row, InputGroup, Form, Button, Card, Table, Image, ListGroup} from 'react-bootstrap';
import { useNavigate, Link} from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {isUndefined } from 'lodash';
import {FiEdit, FiTrash} from 'react-icons/fi';
import PageHeader from '../../PageHeader';
import Error from '../../Error';
const LSocial = (props) => {
    
    const {CompaniesList, setIndex, setCompaniesId} = props;
    const navigate = useNavigate();
    const [showError, setShowError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [info, setInfo] = useState({
        content: '',
        variant: ''
    });
    
    return (
        <React.Fragment>
        <PageHeader title={'Social Networks'} show={loading} className={'mb-2'}/>
            <span>
            Customers can connect with similar people who bought the same design and concept. They can talk about the design and the designers behind it. This group can comment, share, and be active on social networks. Mugam creates value from the different funding mechanisms based on custom content.
            </span>
            <Card className={'border border-0'}>
                <Card.Body className={'p-0 mt-4'}>
                    <a href={'https://app.mugam.in'} target={'_blank'} className={'btn btn-primary'}>Login</a>     
                </Card.Body>
            </Card>
        </React.Fragment>
    )
}

export default connect()(LSocial);