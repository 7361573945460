import { all, fork } from 'redux-saga/effects';
import tokenSaga from '../db/saga/token';
import { insertContactSaga } from '../db/saga/contact';

export default function* rootSaga() {
  yield all([
    fork(insertContactSaga), 
    fork(tokenSaga)
  ]);
}
/*
export default function* rootSaga() {
  const sagas = [
    generateStripeClientKeySaga, 
    stripeReturnResponseSaga, 
    stripeTransferSaga,
    tokenSaga,
    loginSaga,
    registerUserSaga,
    forgetPasswordSaga,
    activateUserSaga,
    generateOtpSaga,
    emailByUserSaga,
    removeFileSaga,
    uploadFileSaga,
    getFileSaga,
    insertFileConnectSaga,
    removeFileConnectSaga, 
    getFileConnectSaga, 
    userFileConnectSaga,
    insertBankSaga,
    removeBankSaga,
    getBankSaga,
    conversionSaga,
    getProfileSaga,
    insertProfileSaga,
    updateProfileSaga,
    stripeTransferSaga,
    interestedCompaniesSaga, removeCompaniesSaga, updateCompaniesSaga,
    listCompaniesSaga, userCompaniesSaga, insertCompaniesSaga, getCompaniesSaga,
    tokenSaga,loginSaga,registerUserSaga, activateUserSaga, forgetPasswordSaga,
    generateOtpSaga,
    emailByUserSaga,insertInvestmentSaga, interestedInvestmentSaga, 
    getInvestmentSaga, userInvestmentSaga,removeInvestmentSaga,updateInvestmentSaga, 
    listInvestmentSaga, insertSharesSaga, getSharesSaga, updateSharesSaga, boughtSharesSaga, 
    companiesSharesSaga, userSharesSaga, insertTransactionSaga, getTransactionSaga, userTransactionSaga,
    insertWalletHistorySaga, insertWalletSaga, getWalletSaga, userWalletHistorySaga, 
    userWalletSaga, updateWalletSaga,
    insertPurchaseSaga, getPurchaseSaga, userPurchaseSaga,
    insertRedeemSaga, getRedeemSaga, userRedeemSaga,
    removeProposalSaga,updateProposalSaga,
    listProposalSaga, userProposalSaga, insertProposalSaga, getProposalSaga,
    insertPurchaseSaga, userPurchaseSaga, getPurchaseSaga, feePurchaseSaga,updatePurchaseSaga
  ];

  yield all(sagas.map(saga =>
    spawn(function* () {
      while (true) {
        try {
          yield call(saga)
          break
        } catch (e) {
          console.log(e)
        }
      }
    }))
  );
}
*/