import React, {useState, useEffect} from 'react';
import moment from 'moment';
import {Col, Row, InputGroup, Form, Button, Card, Table, Image, ListGroup} from 'react-bootstrap';
import { useNavigate, Link} from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {isUndefined } from 'lodash';
import {FiEdit, FiTrash} from 'react-icons/fi';
import PageHeader from '../../PageHeader';
import Error from '../../Error';
const LContact = (props) => {
    
    const {CompaniesList, setIndex, setCompaniesId} = props;
    const navigate = useNavigate();
    const [showError, setShowError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [info, setInfo] = useState({
        content: '',
        variant: ''
    });
    
    return (
        <React.Fragment>
        
        </React.Fragment>
    )
}

export default connect()(LContact);