import React, {useState, useEffect} from 'react';
import {Col, Row, InputGroup, Form, Button, Card, Table, Image, ListGroup} from 'react-bootstrap';
import { useNavigate, Link} from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {isUndefined } from 'lodash';
import {FiEdit, FiTrash} from 'react-icons/fi';
import PageHeader from '../../PageHeader';
import asideSvg from '../../../asset/images/aside.svg';
import aside2Svg from '../../../asset/images/aside2.svg';
import service1Svg from '../../../asset/images/service-icon1.svg';
import service2Svg from '../../../asset/images/service-icon2.svg';

const LTrend = (props) => {
    
    const {CompaniesList, setIndex, setCompaniesId} = props;
    const navigate = useNavigate();
    const [showError, setShowError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [info, setInfo] = useState({
        content: '',
        variant: ''
    });
    
    return (
        <React.Fragment>
          <section id="today" className="services">
    <div className="container-fluid">
      <div className="side-img"> 
        <img src={asideSvg}  alt="" />
      </div>
      <div className="side2-img"> 
        <img src={aside2Svg} alt="" />
      </div>
      <div className="container">
        <div className="row">
          <div className="col-12 col-sm-12 col-lg-4 service-txt">
            <h2>Anything you need,we’ve got you covered</h2>
            <div className="hero-btns service-btn">
              <a data-scroll href={'https://app.mugam.in'} target={'_blank'}>Get in Touch</a>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-lg-4">
            <div className="service-box">
              <img src={service1Svg} alt="" />
              <h3>Wear <br />Design</h3>
              <p>Mugam has a unique prediction algorithm that combines astrology, trending, and machine learning models that suggest what you want to wear every to make a successful day.</p>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-lg-4">
            <div className="service-box">
              <img src={service2Svg} alt="" />
              <h3>Mood<br />Development</h3>
               <p>Mugam collects social trending of the day, visualizes the future, and recommends buying, creating, and inspiring the world.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
          </section>
        </React.Fragment>
    )
}

export default connect()(LTrend);