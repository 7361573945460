import React from 'react';
import {TbBrandFacebook, TbBrandTwitter, TbBrandDribbble} from 'react-icons/tb'
const Footer = () => {
    return (        
        <>
        <footer id='footer'>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h5>Mugam</h5>
              <h3>Intense Emotions</h3>
              <h6>Contact: Tamilnadu, India |
                Email:one@mugam.in |
              Mobile:+91 81222 35963</h6>
              <ul className="contact-nav">
                <li><a data-scroll href="#home">Home</a></li>
                <li><a data-scroll href="#about">About Us</a></li>
                <li><a data-scroll href="#features">Features</a></li>
                <li><a data-scroll href="#today">Today</a></li>
                <li><a data-scroll href="#quote">Quote</a></li>
                <li><a data-scroll href="#investor">Investor</a></li>
                <li><a data-scroll href="#footer">Contact US</a></li>
              </ul>
              <h6>© 2020 - lab63, All Right Reserved</h6>
              <ul className="social">
                <li><a href='#'><TbBrandDribbble size={40} /></a></li>
                <li><a href='#'><TbBrandFacebook size={30} /></a></li>
                <li><a href='#'><TbBrandTwitter size={30} /></a></li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
      </>
    )
}

export default Footer;