import https from '../../axios';
export const doInsertContact = async (params) => {
    const {
        name, short_name, about, url, phone, phoneCode, email, country, created_by, video_url
    } = params;
    try {
        return await https.post('/contact/mail', {
            name, short_name, about, url, phone, phoneCode, email, country, created_by, video_url
        });
    } catch(exception) {
        const error = exception.toJSON();
        return { data: {'success' : false, 'code': error.code, 'message': error.message } };
    }
}