import React, {useState} from 'react';
import './index.scss';
import Header from '../header';
import showSvg from '../../asset/images/ew-login-layout.svg';
import {Container, Row, Col, Image, Spinner, Card} from 'react-bootstrap';
import LAbout from '../../components/About/List';
import LFeature from '../../components/Feature/List';
import IList from '../../components/Idea/List';
import CList from '../../components/Custom/List';
import SList from '../../components/Social/List';
import TList from '../../components/Trend/List';
import IRList from '../../components/Investor/List';
import Footer from '../../layout/footer';

const MainLayout = (props) => {
    return (
    <>    
    <Header />
    <Row className={'align-items-center'}>
            <Col md={12}><TList /></Col>
    </Row>
    <Row className={'align-items-center'}>
            <Col md={12}><LAbout {...props} /></Col>
    </Row>
    
    <Row className={'align-items-center'}>
            <Col md={12}><LFeature {...props} /></Col>
    </Row>
    
    <Row className={'align-items-center'}>
        <Col md={12}><CList /></Col>
    </Row>
    <Row className={'align-items-center'}>
        <Col md={12}><IRList {...props} /></Col>
    </Row>
    <Footer />
    </>
    );
}

export default MainLayout;