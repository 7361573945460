import React, {useState, useEffect} from 'react';
import moment from 'moment';
import {Col, Row, InputGroup, Form, Button, Card, Table, Image, ListGroup} from 'react-bootstrap';
import { useNavigate, Link} from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {isUndefined } from 'lodash';
import {FiEdit, FiTrash} from 'react-icons/fi';
import PageHeader from '../../PageHeader';
import Error from '../../Error';
import Slider from "react-slick";
import asideSvg from '../../../asset/images/aside4.svg';

const LCustom =  ()  => {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };
    return (
        <section id="blog" className="blog">
        <div className="container-fluid">
          <div className="blog-aside">
            
          </div>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h5>Custom Quotes</h5>
                <h2>Contact for Bulk Orders</h2>
              </div>
            </div>
            <div id="blog-drag" className="row">  
                <div className='col-md-4 col-lg-4'>
                <Slider {...settings}>
                    <div className="col-lg-12">
                      <div className='blog-box p-0'>
                        <img src={`https://case.mugam.in/assets/collections/MI167617/store/front/navy.webp`} />
                      </div>
                    </div>  
                    <div className="col-lg-12">
                    <div className='blog-box p-0'>
                        <img src={`https://case.mugam.in/assets/collections/MI167617/store/front/olive.webp`} />
                    </div>
                    </div>
                    <div className="col-lg-12">
                    <div className='blog-box p-0'>
                    <img src={`https://case.mugam.in/assets/collections/MI167617/store/front/yellow.webp`} />
                    </div>
                    </div>
                </Slider>
                </div>
                <div className='col-md-8 col-lg-8'>
                    <Quotes />
                </div>
            </div>
           </div>
        </div>
      </section>
    );
}

const Quotes = () => {
    return (        
        <>
        <section id="quote" className="contact">
            <div className="container-fluid p-4 border border-0 border-secondry rounded">
            <div className="row">
                <div className="col-12">
                <p>
                Contact for custom bulk orders. We deliver at the wholesale price instead of a retail price. You will get a direct pay cheque on delivery if you refer us as an influencer. 
                </p>
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-lg-6 email">
                <input placeholder="Your email" type="email" id="email" pattern=".+@globex.com" size="30" required />
                </div>
                <div className="col-12 col-lg-6 email">
                <input placeholder="Subject" type="subject" id="subject" size="30" required />
                </div>
            </div>
            <div className="row">
                <div className="col-12 message border-4">
                <textarea id="message" name="message" rows="5" cols="1" placeholder={'Message Here'} />
                </div>
                <div className="col-12">
                <div className="hero-btns contact-btn m-5">
                    <a href={'https://app.mugam.in'} target={'_blank'} >Send Quote</a>
                </div>
                </div>
            </div>
            </div>
        </section>
      </>
    )
}

export default connect()(LCustom);