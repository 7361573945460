import { combineReducers } from 'redux';
import token from '../db/reducer/token';
import contact from '../db/reducer/contact';

const appReducer = combineReducers({
  token,
  contact
});

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT_REQUEST') {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;

