import {call, put, takeLatest} from 'redux-saga/effects';
import {
    INSERT_CONTACT_REQUEST,
    INSERT_CONTACT_SUCCESS
} from '../../actionTypes/contact';

import { doInsertContact} from '../../../com/contact';

function* insertContactFetch(params) {
    const json = yield call(doInsertContact, params.payload);   
    yield put({ type: INSERT_CONTACT_SUCCESS, payload: json.data});
}

export function* insertContactSaga() {
    yield takeLatest(INSERT_CONTACT_REQUEST, insertContactFetch)
}