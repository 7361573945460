import React, {useState, useEffect} from 'react';
import {Col, Row, InputGroup, Form, Button, Card, Table, Image, ListGroup} from 'react-bootstrap';
import { useNavigate, Link} from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {isUndefined } from 'lodash';
import {FiEdit, FiTrash} from 'react-icons/fi';
import PageHeader from '../../PageHeader';
import asideSvg from '../../../asset/images/aside.svg';
import aside2Svg from '../../../asset/images/aside2.svg';
import service1Svg from '../../../asset/images/service-icon1.svg';
import service2Svg from '../../../asset/images/service-icon2.svg';

const IRList = (props) => {
    
    const {CompaniesList, setIndex, setCompaniesId} = props;
    const navigate = useNavigate();
    const [showError, setShowError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [info, setInfo] = useState({
        content: '',
        variant: ''
    });
    
    return (
        <React.Fragment>
          <section id="investor" className="services">
    <div className="container-fluid">
      <div className="side-img"> 
        <img src={asideSvg}  alt="" style={{maxHeight:'500px'}}/>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-12 col-sm-12 col-lg-4 service-txt">
            <h5>Investment</h5>
            <h2>Greetings, Are you an Investor?</h2>
            <div className="hero-btns service-btn">
              <a data-scroll href={'https://app.mugam.in'} target={'_blank'}>Get in Touch</a>
            </div>
          </div>
          <div className="col-12 col-sm-8 col-lg-8">
            <div className="service-box">
              <img src={service1Svg} alt="" />
              <h3>Join with Us</h3>
              <p>Mugam is looking for Investors. If you're, Please invest in the mugam and inspire the world by attaching and remembering the people. You can Invest a minimum of 1000 USD and start the journey. Get in touch with me, and I can share the financial and selling statements. Can you reach me to discuss this?</p>
            </div>
          </div>
        </div>
      </div>
    </div>
          </section>
        </React.Fragment>
    )
}

export default connect()(IRList);