import React, {useState, useEffect} from 'react';
import moment from 'moment';
import {Col, Row, InputGroup, Form, Button, Card, Table, Image, ListGroup} from 'react-bootstrap';
import { useNavigate} from 'react-router-dom';
import logoImg from '../../../asset/images/logo.png'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PageHeader from '../../PageHeader';
import { TbAddressBook, TbSocial, TbCurrencyBitcoin } from 'react-icons/tb';
const LFeature = (props) => {
    return (
        <React.Fragment>
            <section className="about" id='features'>
            <div className="container">            
                <h2>Beyond Textile. Features of Mugam</h2>
                <div className={"row"}>
                    <div className={"col-sm-6 col-md-4 col-lg-4 mb-3"}>
                        <Card className={'service-box p-2 m-0'} style={{minHeight:300}}>
                        <Card.Body>
                        <center><TbAddressBook size={60} /></center>
                        <br/>
                        <h5>Submit Idea</h5>
                        <span></span>
                        <p>Mugam wants you to submit your creative design idea. We make a sale on your design and share the profit with you. You can start your earnings with zero investment and creative thought.</p>
                        <a href={'https://app.mugam.in'} target={'_blank'} className={'btn btn-primary'}>Idea</a>
                        </Card.Body>
                        </Card> 
                    </div>
                    <div className={"col-sm-6 col-md-4 col-lg-4 mb-3"}>
                        <Card className={'service-box p-2 m-0'} style={{minHeight:300}}>
                        <Card.Body>
                        <center><TbSocial size={60} /></center>
                        <br/>
                        <h5>Social networking</h5>
                        <span></span>
                        <p>Our relationship continues online even after you buy our product. You can explore like-minded people who purchased the same product and exchange your thoughts with everyone. We give offers to the growing community.</p>
                        <a href={'https://app.mugam.in'} target={'_blank'} className={'btn btn-primary'}>Networking</a>
                        </Card.Body>
                        </Card> 
                    </div>  
                    <div className={"col-sm-6 col-md-4 col-lg-4 mb-3"}>
                        <Card className={'service-box p-2 m-0'} style={{minHeight:300}}>
                        <Card.Body>
                        <center><TbCurrencyBitcoin size={60} /></center>
                        <br/>
                        <h5>Circular economy</h5>
                        <span></span>
                        <p>We invest 3% of the product cost into the cryptocurrency market on your behalf to ensure a circular economy and your personal savings plan.</p>
                        <a href={'https://app.mugam.in'} target={'_blank'} className={'btn btn-primary'}>Invest</a>
                        </Card.Body>
                        </Card> 
                    </div>
                </div>
            </div>
            </section>
        </React.Fragment>
    )
}

export default connect()(LFeature);